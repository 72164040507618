<template>
  <div class="forms-with-steps mt-44 mb-48">
    <div class="flex">
      <div>
        <step
          v-for="(step, index) in steps"
          :key="index"
          :step="step"
          :selected="selected"
          @select-step="checkConfirmChangeForm"
        />
      </div>

      <div class="vertical-line h-auto w-px"></div>

      <div class="w-full">
        <component :is="selected" ref="form" />
      </div>
    </div>

    <div class="horizontal-line mt-108 w-full"></div>
  </div>
</template>

<script>
import Step from './components/Step'
import ConfirmChangeForm from '@/components/forms-with-steps/components/ConfirmChangeForm'

export default {
  name: 'FormsWithSteps',
  components: {
    Step,
  },
  props: {
    steps: {
      required: true,
      type: Array,
    },
    defaultStep: {
      required: false,
      type: String,
    },
  },
  data: () => ({
    selected: undefined,
  }),
  computed: {
    isValid() {
      return this.$refs.form && this.$refs.form.isValid !== false
    },
  },
  created() {
    this.selected = this.defaultStep || this.steps[0].component.name

    for (const step of this.steps) {
      const { component } = step
      this.$options.components[component.name] = component
    }
  },
  methods: {
    checkConfirmChangeForm(componentName) {
      if (!this.$refs.form.$v || !this.$refs.form.$v.$anyDirty) {
        this.selectStep(componentName)
        return
      }

      this.$modal.open(ConfirmChangeForm).then((response) => {
        response.continue && this.selectStep(componentName)
      })
    },
    selectStep(componentName) {
      this.selected = componentName
    },
    reset() {
      this.$refs.form.reset()
    },
    save() {
      const formName = this.$refs.form.save()
      return formName
    },
  },
}
</script>

<style lang="scss" scoped>
.vertical-line,
.horizontal-line {
  border: 1px solid theme('colors.grey.250');
}
</style>
