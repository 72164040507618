<template>
  <div
    class="step flex mb-28 mr-90 cursor-pointer"
    @click="$emit('select-step', step.component.name)"
  >
    <img src="/imgs/bullet.svg" alt="" class="unfilled mr-15" />
    <div :class="style">{{ $t(step.title) }}</div>
  </div>
</template>

<script>
export default {
  name: 'Step',

  props: {
    step: {
      required: true,
      type: Object,
    },

    selected: {
      required: false,
      type: String,
    },
  },

  computed: {
    style() {
      let css = ['text-15', 'text-blue-450']

      if (this.step.component.name === this.selected) {
        css = [...css, 'font-bbva', 'font-medium']
      }

      return css
    },
  },
}
</script>

<style lang="scss" scoped>
.step {
  width: 277px;
}
</style>
