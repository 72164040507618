<template>
  <div class="app-datepicker">
    <div class="input-wrapper flex justify-end" @change="setData" @click="openCalendar">
      <app-input
        :value="formattedDate"
        class="w-full"
        :maxlength="10"
        :placeholder="$t('SHARED.DATE')"
        :disabled="isInputDisabled"
        @input="onDirectTyping"
      />
      <template v-if="isClearEnabled && formattedDate">
        <img
          class="icon-calendar absolute cursor-pointer mt-12"
          src="/imgs/cross.svg"
          :alt="$t('SHARED.CALENDAR')"
          @click="onClearInput"
        />
      </template>
      <template v-else>
        <img
          class="icon-calendar absolute cursor-pointer mt-12"
          src="/imgs/calendar.svg"
          :alt="$t('SHARED.CALENDAR')"
          @click="$modal.close"
        />
      </template>
    </div>
    <datepicker
      ref="calendar"
      class="calendar"
      :value="date"
      :full-month-name="true"
      :monday-first="true"
      :language="es"
      :disabled-dates="disabledDates"
      :open-date="customOpenDate"
      input-class="input-class"
      @opened="onCalendarOpen"
      @selected="onDateSelected"
      @closed="onCalendarClose"
    />
    <p v-if="showError" class="error-message text-red-500 text-15 mt-12">
      {{ $t('PERSONAL_DATA.FORMS.FAMILY.SPECIALCHARACTER') }}
    </p>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { DateFormatter } from '@/shared/utils/dateFormatter'
import { es } from 'vuejs-datepicker/dist/locale'
import AppInput from '@/components/form/AppInput'

export default {
  name: 'AppDatepicker',
  components: {
    Datepicker,
    AppInput,
  },
  props: {
    date: {
      type: Date,
      default: () => new Date(),
    },
    isBlank: {
      type: Boolean,
      required: false,
    },
    dateString: {
      type: String,
    },
    from: {
      type: Date,
      default: () => new Date(),
    },
    to: {
      type: Date,
    },
    isInputDisabled: {
      type: Boolean,
      default: false,
    },
    isClearEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    es: es,
    dateIsSet: false,
    showError: false,
  }),
  computed: {
    customOpenDate() {
      if (this.inputIsEmpty()) {
        return new Date()
      }

      return this.date ? this.date : new Date()
    },
    formattedDate() {
      if (this.inputIsEmpty()) {
        return ''
      }

      if (this.isBlank && !this.dateIsSet) {
        return ''
      }

      return this.date ? DateFormatter.formatDateInSpanish(this.date) : ''
    },
    disabledDates() {
      return {
        from: this.from,
        to: this.to,
      }
    },
  },
  methods: {
    onDirectTyping(value) {
      if (value !== '') {
        var expression = /^(0?[1-9]|[12][0-9]|3[01])[\\/](0?[1-9]|1[012])[\\/]\d{4}$/
        var format = new RegExp(expression)
        var res = format.test(value)
        if (res) {
          this.dateString = value
        } else {
          this.dateString = ''
        }

        return
      }

      const date = new Date(2999, 11, 31)
      this.onDateSelected(date)
    },
    setData() {
      if (this.dateString || this.dateString !== '') {
        this.showError = false
        const split = this.dateString.split('/')
        const date = new Date(split[2], split[1] - 1, split[0])
        return this.onDateSelected(date)
      }
      this.showError = true
      this.$emit('date-is-ok', this.showError)
    },
    inputIsEmpty() {
      if (!this.date) {
        return true
      }
      const EMPTY_FIELD_VALUE = '2999-12-31'
      const date = this.date
      const year = date.getFullYear()
      const month = date.getMonth()
      const day = date.getDate()
      return EMPTY_FIELD_VALUE === `${year}-${month + 1}-${day}`
    },
    onCalendarOpen() {
      this.$emit('calendar-opened')
    },
    openCalendar() {
      this.$refs.calendar.showCalendar()
    },
    onDateSelected(date) {
      //TODO: Esto da un pete en consola, debería quitarse
      this.dateIsSet = true
      this.showError = false
      this.date = date
      this.$emit('date-is-ok', this.showError)
      this.$emit('date-selected', date)
    },
    onCalendarClose() {
      this.setData()
      this.$emit('calendar-closed')
    },
    onClearInput(evt) {
      evt.stopPropagation()
      this.dateIsSet = false
    },
  },
}
</script>

<style lang="scss" scoped>
.app-datepicker {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .input-wrapper {
    width: 100%;
  }

  .icon-calendar {
    margin-left: -16px;
  }
}

.vdp-datepicker.calendar {
  // stylelint-disable-next-line selector-max-compound-selectors
  ::v-deep div:first-child input {
    display: none;
  }
}

// stylelint-disable-next-line selector-class-pattern
::v-deep .vdp-datepicker__calendar {
  left: -204px;
}
</style>
