<template>
  <div class="confirm-change-form flex flex-col items-center pt-64 pb-100 px-64 text-grey-900">
    <img src="/imgs/password.svg" alt="padlock" />
    <h1 class="mt-24 text-32 leading-150 -tracking-0308 text-center">
      {{ $t('PERSONAL_DATA.FORMS.COMPONENTS.CONFIRM_CHANGE_FORM.TITLE') }}
    </h1>
    <h2 class="mt-17 font-bbva text-18 leading-153 font-medium">
      {{ $t('PERSONAL_DATA.FORMS.COMPONENTS.CONFIRM_CHANGE_FORM.SUBTITLE') }}
    </h2>
    <p class="mt-16 text-18 leading-156 text-center">
      {{ $t('PERSONAL_DATA.FORMS.COMPONENTS.CONFIRM_CHANGE_FORM.ADVISE_ONE') }}
    </p>
    <p class="mt-16 text-18 leading-156 text-center">
      {{ $t('PERSONAL_DATA.FORMS.COMPONENTS.CONFIRM_CHANGE_FORM.ADVISE_TWO') }}
    </p>
    <div class="buttons-wrapper form-row-wrapper md:flex mt-80 justify-end">
      <a
        href="javascript: void(0)"
        class="mt-16 mr-70 text-blue-400 font-bbva font-medium text-15 leading-160"
        @click="$modal.close()"
        >{{ $t('SHARED.CANCEL') }}</a
      >
      <app-button class="continue bg-blue-400 font-bbva mr-28" @click="continueAndClose">
        {{ $t('PERSONAL_DATA.FORMS.COMPONENTS.CONFIRM_CHANGE_FORM.CONTINUE') }}
      </app-button>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton'

export default {
  name: 'ConfirmChangeForm',
  components: {
    AppButton,
  },
  methods: {
    continueAndClose() {
      this.$modal.close(true, {
        continue: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-sae-error {
  .close {
    width: 205px;
  }
}
</style>
