export class DateFormatter {
  static formatDateInSpanish(date, options) {
    return date.toLocaleDateString('es-ES', options)
  }

  static formatHyphenDate(date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    return `${year}-${month}-${day}`
  }
}
