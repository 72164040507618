<template>
  <div class="information text-15 font-semibold text-blue-500">
    <img :src="icon" alt="info" class="inline icon" />
    <span class="text">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Information',
  props: {
    text: {
      required: true,
      type: String,
    },
    icon: {
      type: String,
      default: '/imgs/info.svg',
    },
  },
}
</script>
