<template>
  <section>
    <div class="container mx-auto internal-padding">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppSection',
}
</script>
